<template>
  <div id="users-list" class="chat-user-list-wrapper list-group">
    <ul class="chat-users-list contact-list media-list">
      <template v-if="projects.length">
        <li v-for="project in projects" :class="{ 'active': project.id == selectedProjectId }"
            @click="$emit('selectProject', project.id)">
        <span class="avatar bg-transparent">
          <img :src="IconPath" alt="Generic placeholder image" class="rounded border-0"/>
        </span>
          <div class="chat-info">
            <h5 class="mb-0">{{ project.name }}</h5>
            <p class="card-text text-truncate">
              {{ project.party.name }}
            </p>
          </div>
        </li>
      </template>
      <li v-else>
        <h6 class="mb-0 text-center">No Projects Found</h6>
      </li>
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </ul>
  </div>
</template>

<script setup>
import IconPath from '@/assets/check-circle.png';
import Pagination from '@/components/atom/Pagination'

const emit = defineEmits(['onPageChange'])
const props = defineProps({
  projects: {
    type: Array,
    default: []
  },
  selectedProjectId: {
    type: String,
    default: '',
  },
  offset: {
    type: String
  }
})

const onPageChange = page => {
  emit('onPageChange', page)
};
</script>

<style lang="scss">
.chat-application {
  .sidebar-content {
    .chat-user-list-wrapper {
      width: 300px;
      overflow-y: scroll;
      overflow-x: hidden;

      ul.pagination {
        margin-top: 10px;
      }

      li.page-item {
        padding: 0 !important;
      }
    }
  }
}
</style>
