<template>
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="bg-gradient-secondary text-center p-50">
          INCOME
        </div>
        <div class="mb-0 bg-blue-light h3 p-1 text-center">
          {{ commaFormat(totalLIncome) }}
        </div>
        <div class="card-body p-0">
          <table class="table table-bordered">
            <tr>
              <th class="p-25">Income Head</th>
              <th class="p-25 float-end">Amount</th>
            </tr>
            <tr v-for="incomeBalance in accountsBalance.income">
              <td class="p-25">{{ incomeBalance.name }}</td>
              <td class="p-25 float-end">{{ commaFormat(incomeBalance.total) }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="bg-gradient-secondary text-center  p-50">
          EXPENSES
        </div>
        <div class="mb-0 bg-blue-light h3 p-1 text-center">
          {{ commaFormat(totalLExpense) }}
        </div>
        <div class="card-body p-0">
          <table class="table table-bordered">
            <tr>
              <th class="p-25">Expense Head</th>
              <th class="p-25 float-end">Amount</th>
            </tr>
            <tr v-for="expenseBalance in accountsBalance.expense">
              <td class="p-25">{{ expenseBalance.name }}</td>
              <td class="p-25 float-end">{{ commaFormat(expenseBalance.total) }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="bg-gradient-secondary text-center  p-50">
          PROFIT / (LOSS)
        </div>
        <div class="bg-blue-light h3 p-1 text-center">
          {{ commaFormat(totalLIncome - totalLExpense) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed} from "vue";
import figureFormatter from "@/services/utils/figureFormatter";

const {commaFormat} = figureFormatter()
const props = defineProps({
  accountsBalance: {
    type: Object,
    default: {
      income: [],
      expense: []
    }
  }
})

const totalLIncome = computed(() => {
  if (props.accountsBalance.income) {
    return props.accountsBalance.income.reduce((sum, {total}) => sum + Number(total), 0)
  }
  return 0
})

const totalLExpense = computed(() => {
  if (props.accountsBalance.expense) {
    return props.accountsBalance.expense.reduce((sum, {total}) => sum + Number(total), 0)
  }
  return 0
})

</script>


<style scoped>

</style>
