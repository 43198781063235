<template>
  <div class="content-right">
    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row">
      </div>
      <div class="content-body">
        <div class="body-content-overlay"></div>
        <!-- Main project area -->
        <section class="chat-app-window">
          <!-- To load Conversation -->
          <div class="start-chat-area d-none">
            <div class="mb-1 start-chat-icon">
              <i data-feather="message-square"></i>
            </div>
            <h4 class="sidebar-toggle start-chat-text">Select Project</h4>
          </div>
          <!--/ To load Conversation -->

          <!-- Active Project -->
          <div class="active-chat">
            <!-- Project Header -->
            <div class="chat-navbar">
              <header class="chat-header">
                <div class="d-flex align-items-center">
                  <div @click="$emit('sidebarActiveInactive')" class="sidebar-toggle d-block d-lg-none me-1">
                    <svg class="svg-inline--fa fa-bars" data-v-187c8d15="" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z"></path></svg>
                  </div>
                  <div class="avatar bg-transparent avatar-border user-profile-toggle m-0 me-1">
                    <img :src="IconPath" width="42" class="border-0 rounded" :alt="projectOverview.name"/>
                  </div>
                  <h6 class="mb-0">
                    {{ projectOverview.name }}<br>
                    <small>{{ projectOverview.party?.name }}</small>
                  </h6>
                </div>
                <div @click="$emit('handleEdit', projectOverview.id)" class="d-flex align-items-center">
                  <i class="cursor-pointer fa fa-edit d-sm-block font-medium-2 me-1"></i>
                </div>
              </header>
            </div>
            <!--/ Project Header -->

            <!-- User Chat messages -->
            <div class="user-chats">
              <div class="chats">
                <ul class="nav nav-tabs">
                  <li v-for="tab in tabs" :key="tab.id" class="nav-item">
                    <a
                        :href="`#${tab.id}`"
                        :class="['nav-link', { active: activeTabId === tab.id }]"
                        @click="setActiveTab(tab)"
                    >
                      {{ tab.title }}
                    </a>
                  </li>
                </ul>
                <component :is="activeTab" :accountsBalance="accountsBalance"/>
              </div>
            </div>
            <!-- User Project messages -->
          </div>
          <!--/ Active Project -->
        </section>
        <!--/ Main project area -->
      </div>
    </div>
  </div>
</template>

<script setup>
import IconPath from '@/assets/check-circle.png';
import Overview from "@/views/page/company/procurement/project/register/tabs/Overview.vue";
import Statements from "@/views/page/company/procurement/project/register/tabs/Statements.vue";
import ProjectBVA from "@/views/page/company/procurement/project/register/tabs/ProjectBVA.vue";
import ProjectBudget from "@/views/page/company/procurement/project/register/tabs/ProjectBudget.vue";
import BoQStatus from "@/views/page/company/procurement/project/register/tabs/BoQStatus.vue";
import ProjectRAndPBVA from "@/views/page/company/procurement/project/register/tabs/ProjectRAndPBVA.vue";
import TimeActivityBVA from "@/views/page/company/procurement/project/register/tabs/TimeActivityBVA.vue";
import More from "@/views/page/company/procurement/project/register/tabs/More.vue";
import {ref} from "vue";

const activeTab = ref(Overview)
const activeTabId = ref('overview')
const props = defineProps({
  projectOverview: {
    type: Object,
    default: {
      project_accounts: [],
      party: {},
    }
  },
  accountsBalance: {
    type: Object,
    default: {
      income: [],
      expense: []
    }
  }
})

const tabs = [
  {id: 'overview', title: 'Overview', component: Overview},
  {id: 'statements', title: 'Statements', component: Statements},
  {id: 'project-r&p-bva', title: 'Project R&P', component: ProjectRAndPBVA},
  {id: 'project-budget', title: 'Project Budget', component: ProjectBudget},
  {id: 'project-bva', title: 'Project BVA', component: ProjectBVA},
  {id: 'boq-status', title: 'BoQ Status', component: BoQStatus},
  {id: 'more', title: 'More', component: More},
]

const setActiveTab = tab => {
  activeTab.value = tab.component;
  activeTabId.value = tab.id;
};


</script>

<style scoped>
.user-chats {
  background-image: none !important;
  overflow-y: scroll;
  height: calc(100% - 65px);
  overflow-x: hidden;
}
</style>
