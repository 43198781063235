<template>
  <div class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton :showBtn="false" :showAddNew="false" title="Bill of Quantity (BoQ) Status Report"/>
      <div class="row match-height mt-1">
        <div v-if="props.callFrom !== 'register'" class="col-12 col-sm-6 col-md-4 col-lg-6">
          <label for="colFormLabel" >Select Project</label>
          <v-select
            placeholder="Select Project"
            :options="projects"
            label="name"
            :reduce="name => name.id"
            v-model="projectId"
            @option:selected="onSelectProject"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel">Budget</label>
          <v-select
            placeholder="Select Budget"
            v-model="budgetMasterId"
            :options="donors"
            label="name"
            :reduce="name => name.id"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" >Select Cost Centre</label>
          <v-select
            placeholder="Select Cost Centre"
            v-model="costCentreId"
            :options="costCentres"
            label="name"
            :reduce="name => name.id"
          />
        </div>
        <div class="clearfix"></div>
        <div class="col-2 col-12 col-sm-6 col-md-4 col-lg-3 mt-2">
          <button
              style="min-width: 64px;"
              @click="exportReport('donor-report-bva')"
              class="btn btn-primary btn-block waves-effect waves-float waves-light"
              :disabled="disableReportExportBtn"
              :class="{'disabled' : disableReportExportBtn}"
          >Export
          </button>
        </div>
      </div>
    </div>
    <Loader v-if="isLoading" />
  </div>
</template>

<script setup>
const props = defineProps({
  callFrom: {
    type: String,
    default: ''
  }
})

import TitleButton from '@/components/atom/TitleButton'
import Loader from '@/components/atom/LoaderComponent'
import {inject, onMounted, ref, watch} from "vue";
import handleProjects from "@/services/modules/procurement/project";
import {useRoute} from "vue-router";
import handleCostCentres from "@/services/modules/ngo/costCentre";
import handleInventory from "@/services/modules/inventory";

const {fetchProjects, fetchProjectDonors} = handleProjects();
const {fetchCostCentreList} = handleCostCentres()
const {fetchHome} = handleInventory();

const $route = useRoute();
const showError = inject('showError');
const query = `?company_id=${$route.params.companyId}`;
const token = localStorage.getItem('token');
const projects = ref([]);
const donors = ref([]);
const costCentres = ref([]);
const projectId = ref(null);
const budgetMasterId = ref(null);
const costCentreId = ref(null);
const isLoading = ref(false);
const donorPVAReport = ref([]);
const budgetBreakDown = ref({});
const budgetHistory = ref({});
const actual = ref({});
const disableReportExportBtn = ref(false);

watch(() => $route.query.projectId, (newProjectId) => {
  projectId.value = newProjectId;
  onSelectProject()
});

// Methods
const onSelectProject = () => {
  isLoading.value = true;

  disableReportExportBtn.value = false;
  const selectedProject = projects.value.find(project => project.id == projectId.value)
  const requiredValues = [selectedProject.interval, selectedProject.start_date, selectedProject.end_date];
  if(selectedProject && (requiredValues.includes(null) || requiredValues.includes('')) ) {
    disableReportExportBtn.value = true;
    showError('Project Interval, Start Date or End date is missing. Please add them to generate report')
  }

  donorPVAReport.value = [];
  budgetHistory.value = {};
  budgetBreakDown.value = {};
  fetchProjectDonors(projectId.value, query)
    .then( res => {
      if(res.status) {
        budgetMasterId.value = null;
        donors.value = res.data;
      }
    })
    .catch( err => {
      showError(err.response.message);
    })
    .finally( () => isLoading.value = false)
}

const exportReport = (type = 'project-lifecycle-boq') => {
  if (projectId.value === null) {
    showError('Please select a project');
    return;
  }
  let objQuery = {
    company_id: $route.params.companyId,
    budget_master_id: budgetMasterId.value ?? '',
    cost_centre_id: costCentreId.value ?? '',
    _token: token,
    report_type: "boq-status-report",
    report_title: "Bill of Quantity (BoQ) Status Report"
  }
  let params = Object.entries(objQuery).map(([key, val]) => `${key}=${val}`).join('&');
  let url = `${process.env.VUE_APP_BASE_URL}/export/${type}/${projectId.value}?${params}`;
  let a = document.createElement('a');
  a.setAttribute('href', url);
  a.click();
}

onMounted( () => {
  isLoading.value = true;
  const projectQuery = `${query}&time_and_interval=1`
  Promise.all([
    fetchProjects(projectQuery).then( res => {
      if(res.status) {
        projects.value = res.data;
      }
    }),
    fetchCostCentreList(query).then( res => {
      if(res.status) {
        costCentres.value = res.data;
      }
    })
  ])
    .then ( () => {
      if ($route.query.projectId) {
        projectId.value = $route.query.projectId
        onSelectProject()
      }
      isLoading.value = false
    })
    .catch( (err) => {
      isLoading.value = false
      showError( err.response.message )
    })
    .finally( () => isLoading.value = false)
})

</script>
